import { forceMobile } from "./force_mobile.js";
import { onceEach } from "./once.js";

const useAllowComputePressureAttribute = window.location.hostname.endsWith("recipal.com");

export const VideoModal = {
  template: `
    <aside class="modal video-modal is-active">
      <div class="modal-background" data-closes></div>
      <div class="modal-content">
        <div class="box">
          <h2 class="subtitle" data-title></h2>
          <figure class="youtube-video image is-16by9" data-video>
          </figure>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" data-closes></button>
    </aside>
  `,

  youtubeHref(videoId, { autoPlay, timestamp }) {
    return (
      `https://www.youtube.com/embed/${videoId}?` +
      `wmode=transparent&rel=0&autoplay=${autoPlay ? "1" : "0"}` +
      `&start=${timestamp}`
    );
  },

  youtubeElement(href) {
    return `
      <iframe class="has-ratio" src="${href}" frameborder="0" wmode="Opaque" allowfullscreen ${
        useAllowComputePressureAttribute ? 'allow="compute-pressure"' : ""
      }>
      </iframe>
     `;
  },

  createModal({ title, videoId, autoPlay, timestamp }) {
    const placeHolder = document.createElement("div");
    placeHolder.insertAdjacentHTML("afterbegin", VideoModal.template);

    const modal = placeHolder.firstElementChild;

    const titleElement = modal.querySelector("[data-title]");
    if (title) {
      titleElement.insertAdjacentText("afterbegin", title);
    } else {
      titleElement.remove();
    }

    modal
      .querySelector("[data-video]")
      .insertAdjacentHTML(
        "afterbegin",
        this.youtubeElement(this.youtubeHref(videoId, { autoPlay, timestamp }))
      );

    return modal;
  },

  async showModal(modal) {
    const removeModal = () => {
      modal.remove();
      document.removeEventListener("keydown", keydownListener);
    };

    let dismissModal;

    const resultPromise = new Promise((resolve) => {
      dismissModal = () => {
        removeModal();
        resolve();
      };
    });

    const keydownListener = document.addEventListener("keydown", (ev) => {
      if (ev.key == "Escape") {
        ev.preventDefault();
        dismissModal();
      }
    });

    modal.querySelectorAll("[data-closes]").forEach((element) => {
      element.addEventListener("click", dismissModal);
    });

    document.body.appendChild(modal);
    return resultPromise;
  },

  async show({ title, videoId, autoPlay, timestamp }) {
    const modal = this.createModal({ title, videoId, autoPlay, timestamp });

    const mobileLock = forceMobile();
    const result = await this.showModal(modal);
    mobileLock.restore();
    return result;
  },
};

const scan = (document) => {
  onceEach(document.querySelectorAll(".video-launch-link"), "video-modal", (link) => {
    link.addEventListener("click", (ev) => {
      ev.preventDefault();

      const videoId = link.dataset.videoId || link.attributes.id;
      const videoTitle = link.dataset.videoTitle || link.attributes.title;
      const videoTimestamp = link.dataset.videoTimestamp || 0;
      VideoModal.show({
        videoId: videoId,
        title: videoTitle,
        timestamp: videoTimestamp,
        autoPlay: true,
      });
    });
  });
};

document.addEventListener("DOMContentLoaded", () => scan(document));
document.addEventListener("partial:replace", (ev) => scan(ev.container));
