/*
  This module is a simple inline tab switching implementation intended to work with
  Bulma's Tab DOM structure.

  <!-- Normal Bulma tab, with data-tab-set and data-tab attributes inserted -->
  <div class="tabs" data-tab-set="food-menu">
    <ul>
      <li class="is-active" data-tab="fish">
        <a>Fish</a>
      </li>
      <li data-tab="chicken">
        <a>Chicken</a>
      </li>
    </ul>
  </div>

  <!-- The panels that will be shown and hidden based on the active tab -->
  <div data-tab="food-menu.fish">
    Fish Menu
  </div>

  <div data-tab="food-menu.chicken" class="is-hidden">
    Chicken Menu
  </div>
*/
import { onceEach } from "./once.js";

const enableTab = (document, tabSetName, tabName) => {
  /* first, adjust is-active on all tab headers */
  document.querySelectorAll(`[data-tab-set='${tabSetName}'] li[data-tab]`).forEach((li) => {
    li.classList.toggle("is-active", li.dataset.tab === tabName);
  });

  /* Hide and show corresponding tab bodies */
  document.querySelectorAll(`[data-tab^='${tabSetName}.']`).forEach((panel) => {
    const [connectedSet, connectedName] = panel.dataset.tab.split(".");
    panel.classList.toggle("is-hidden", connectedSet == tabSetName && connectedName !== tabName);
  });
};

const maybeEnableTabFromUrl = (document) => {
  const urlParams = new URLSearchParams(window.location.search);
  const tabParam = urlParams.get("tab");

  if (tabParam) {
    const [tabSetName, tabName] = tabParam.split(".");

    if (tabSetName && tabName) {
      enableTab(document, tabSetName, tabName);
    } else {
      console.warn(
        `Invalid tab parameter format. Expected "tabSetName.tabName", got "${tabParam}".`
      );
    }
  }
};

const scan = (document) => {
  const tabSetSelector = ".tabs[data-tab-set]";

  onceEach(document.querySelectorAll(tabSetSelector), "tabs-set", (tabDiv) => {
    let firstTab = null;
    let activeTab = null;

    tabDiv.querySelectorAll("li[data-tab]").forEach((li) => {
      firstTab ??= li;
      /* Add click handler to nested link, but allow disabled tabs */
      li.querySelector("a")?.addEventListener("click", (ev) => {
        ev.preventDefault();
        enableTab(document, tabDiv.dataset.tabSet, li.dataset.tab);
      });

      if (li.classList.contains("is-active")) {
        activeTab ??= li;
      }
    });

    activeTab ??= firstTab;
    if (activeTab !== null) {
      enableTab(document, activeTab.closest(tabSetSelector).dataset.tabSet, activeTab.dataset.tab);
    }
  });
};

document.addEventListener("DOMContentLoaded", () => {
  scan(document);
  maybeEnableTabFromUrl(document);
});
document.addEventListener("partial:replace", (ev) => scan(ev.container));
